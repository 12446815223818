import React from 'react';

import './App.css';

import { Buffer } from 'buffer';
import { Field, Form, Formik } from 'formik';

interface Event {
  id: string;
  title: string;
};

const App = () => {
  const [events, setEvents] = React.useState<Event[]>([]);
  const [sent, setSent] = React.useState(false);

  React.useEffect(() => {
    fetch('https://development-api.useat.eu/events')
      .then((response) => response.json())
      .then((data) => setEvents(data.result));
  }, []);

  const sendTicket = (props: { first_name: string, last_name: string, email: string, phone_number: string, event_id: string, language: string }) => {
    fetch('https://development-api.useat.eu/tickets', {
      method: 'POST',
      headers: {
        'Authorization': `Basic ${Buffer.from(`TEST:TEST`).toString('base64')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customer: {
          first_name: props.first_name,
          last_name: props.last_name,
          email: props.email,
          phone: props.phone_number,
          language: props.language,
        },
        company_place_event_id: props.event_id,
        external_id: (Math.random() + 1).toString(36).substring(7),
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data));
  };

  if (sent) {
    return (
      <div className="flex items-center justify-center p-12">
        <div className="mx-auto w-full max-w-[550px]">
          <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
            <div className="flex">
              <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
              <div>
                <p className="font-bold">Ticket envoyé</p>
                <p className="text-sm">Votre ticket a été envoyé à USeat, vous allez bientot recevoir un email et / ou un SMS d'invitation</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center p-12">
      <div className="mx-auto w-full max-w-[550px]">
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            event_id: '',
            language: '',
          }}
          onSubmit={(values, { resetForm }) => {
            if (!values.event_id || values.event_id === 'before') {
              return alert('Please select an event');
            }
            sendTicket(values);
            resetForm();
            setSent(true);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="mb-5">
                <label className="mb-3 block text-base font-medium text-[#07074D]">Prénom</label>
                <input
                  type="text"
                  name="first_name"
                  placeholder="John"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                />
              </div>
              <div className="mb-5">
                <label className="mb-3 block text-base font-medium text-[#07074D]">Nom</label>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Doe"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                />
              </div>
              <div className="mb-5">
                <label className="mb-3 block text-base font-medium text-[#07074D]">Adresse Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="example@domaine.com"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </div>
              <div className="mb-5">
                <label className="mb-3 block text-base font-medium text-[#07074D]">Numéro de téléphone</label>
                <input
                  type="text"
                  name="phone_number"
                  placeholder="+33XXXXXXXXX"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number}
                />
              </div>
              <div className="mb-5">
                <label className="mb-3 block text-base font-medium text-[#07074D]">Evénement</label>
                <Field
                  as="select"
                  name="event_id"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                >
                  <option key="before" value="">Choisir un événement</option>
                  {events.map((event, index) => (
                    <option key={index} value={event.id}>{event.title} ({event.id})</option>
                  ))}
                </Field>
              </div>
              <div className="mb-5">
                <label className="mb-3 block text-base font-medium text-[#07074D]">Langue de l'invitation</label>
                <Field
                  as="select"
                  name="language"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                >
                  <option key="before" value="">Choisir une langue préférée</option>
                  <option key="fr" value="fr">Français</option>
                  <option key="en" value="en">Anglais</option>
                </Field>
              </div>
              <div>
                <button
                  className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none"
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default App;
